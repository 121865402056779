<template>
    <div class="pb-1">
        <!-- PACIENTE SECTION -->
        <b-row>
            <b-col>
                <b-card>
                    <b-row>
                        <b-col cols="auto">
                            <b-avatar
                                variant="info"
                                rounded
                                size="100"
                                class="position-relative"
                            >
                            </b-avatar>
                        </b-col>
                        <b-col
                            class="d-flex flex-row flex-wrap align-items-center mr-2"
                        >
                            <div class="col-auto px-2 py-1">
                                <div class="mb-1">
                                    <feather-icon
                                        icon="UserIcon"
                                        size="25"
                                        class="mr-1"
                                    ></feather-icon>
                                    {{ paciente.nombre }}
                                    {{ paciente.apellido }}
                                </div>
                                <div>
                                    <feather-icon
                                        icon="CheckIcon"
                                        size="25"
                                        class="mr-1"
                                    ></feather-icon>

                                    <b-badge
                                        v-if="paciente.activo"
                                        rounded
                                        variant="success"
                                    >
                                        Activo
                                    </b-badge>
                                    <b-badge v-else rounded variant="danger ">
                                        Inactivo
                                    </b-badge>
                                </div>
                            </div>
                            <div class="col-auto px-2 py-1">
                                <div class="mb-1">
                                    <feather-icon
                                        icon="HashIcon"
                                        size="25"
                                        class="mr-1"
                                    ></feather-icon>
                                    {{ paciente.identificacion }}
                                </div>
                                <div>
                                    <feather-icon
                                        icon="FlagIcon"
                                        size="25"
                                        class="mr-1"
                                    ></feather-icon>
                                    {{ getEdad(paciente.fecha_nacimiento) }}
                                    años
                                </div>
                            </div>
                            <div class="col-auto px-2 py-1">
                                <div class="mb-1">
                                    <feather-icon
                                        icon="CalendarIcon"
                                        size="25"
                                        class="mr-1"
                                    ></feather-icon>
                                    {{ formatDate(paciente.fecha_nacimiento) }}
                                </div>
                                <div>
                                    <feather-icon
                                        icon="CompassIcon"
                                        size="25"
                                        class="mr-1"
                                    ></feather-icon>
                                    {{ getGenero(paciente.genero) }}
                                </div>
                            </div>
                            <div class="col-auto px-2 py-1">
                                <div class="mb-1">
                                    <feather-icon
                                        icon="MailIcon"
                                        size="25"
                                        class="mr-1"
                                    ></feather-icon>
                                    {{ paciente.email }}
                                </div>
                                <div>
                                    <feather-icon
                                        icon="PhoneIcon"
                                        size="25"
                                        class="mr-1"
                                    ></feather-icon>
                                    {{ paciente.telefono }}
                                </div>
                            </div>
                        </b-col>
                        <div class="col text-right position-absolute">
                            <b-button
                                variant="flat-success"
                                pill
                                class="p-50"
                                @click="abrirSidebarEditarPaciente"
                            >
                                <feather-icon
                                    icon="EditIcon"
                                    size="20"
                                ></feather-icon>
                            </b-button>
                            <b-button
                                variant="flat-danger"
                                pill
                                class="p-50"
                                @click="abrirModalEliminarPaciente"
                            >
                                <feather-icon
                                    icon="Trash2Icon"
                                    size="20"
                                ></feather-icon>
                            </b-button>
                        </div>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <!-- ENTIDADES SECTION -->
        <b-row>
            <b-col>
                <b-card>
                    <b-card-title>Entidades</b-card-title>
                    <div class="d-flex flex-nowrap overflow-auto py-1">
                        <div v-if="!entidades.length" class="m-auto">
                            <p class="text-large-2 text-center">
                                Este paciente no tiene entidades aún.
                            </p>
                        </div>
                        <div
                            v-for="(entidad, index) in entidades"
                            :key="index"
                            class="d-flex pl-1 flex-shrink-0"
                        >
                            <div
                                :class="{
                                    'border-success p-25 rounded':
                                        entidad.estado == 1,
                                }"
                            >
                                <b-avatar variant="dark" size="40" rounded>
                                    <feather-icon
                                        icon="HomeIcon"
                                        size="30"
                                    ></feather-icon>
                                </b-avatar>
                            </div>

                            <div class="ml-1">
                                <p class="mb-0">{{ entidad.entidad }}</p>
                                <small>{{
                                    formatDate(entidad.fecha_afiliacion)
                                }}</small>
                                -
                                <small>{{
                                    formatDate(entidad.fecha_desafiliacion) ||
                                    "Ahora"
                                }}</small>
                            </div>
                        </div>
                    </div>
                </b-card>
            </b-col>
        </b-row>
        <!-- DISPOSITIVOS SECTION -->
        <b-row>
            <b-col>
                <b-card>
                    <b-card-title>Dispositivos</b-card-title>

                    <div class="row">
                        <div class="col text-right">
                            <b-button
                                variant="primary"
                                pill
                                class="px-75 mr-1"
                                @click="abrirSidebarAgregarDispositivo"
                            >
                                <feather-icon icon="PlusIcon"></feather-icon>
                            </b-button>

                            <b-button
                                variant="success"
                                pill
                                class="px-75"
                                @click="canEdit = !canEdit"
                                :disabled="!dispositivos.length"
                            >
                                <feather-icon icon="Edit2Icon"></feather-icon>
                            </b-button>
                        </div>
                    </div>

                    <div class="d-flex overflow-auto flex-nowrap">
                        <div v-if="!dispositivos.length" class="m-auto">
                            <p>
                                Este paciente no tiene ningún dispositivo
                                agregado.
                            </p>
                        </div>
                        <div
                            v-for="(dispositivo, index) in dispositivos"
                            :key="index"
                            class="m-2 p-1 border br-1 cursor-pointer"
                        >
                            <div>
                                <div v-if="canEdit">
                                    <!-- botón retirar dispositivo -->
                                    <b-button
                                        variant="flat-danger"
                                        pill
                                        class="p-25 mr-1"
                                        @click="
                                            abrirModalRetirarDispositivo(
                                                dispositivo.id
                                            )
                                        "
                                    >
                                        <feather-icon
                                            icon="Trash2Icon"
                                        ></feather-icon>
                                    </b-button>
                                </div>
                                <div
                                    @click="
                                        dispositivo.showDetalle =
                                            !dispositivo.showDetalle
                                    "
                                >
                                    <b-avatar
                                        v-if="dispositivo.showDetalle == false"
                                        variant="info"
                                        size="200"
                                        rounded
                                        class="dispositivo animate__animated animate__flipInY"
                                        :src="dispositivo.foto"
                                    >
                                        <feather-icon
                                            v-if="dispositivo.showDetalle"
                                            icon="HardDriveIcon"
                                            size="60"
                                        ></feather-icon>
                                    </b-avatar>
                                </div>

                                <div
                                    v-if="dispositivo.showDetalle == true"
                                    style="height: 200px; width: 200px"
                                    class="overflow-auto animate__animated animate__flipInY"
                                    @click="
                                        dispositivo.showDetalle =
                                            !dispositivo.showDetalle
                                    "
                                >
                                    <div class="container">
                                        <div
                                            style="height: 50px"
                                            class="text-center font-medium-3 font-weight-bold"
                                        >
                                            <p>{{ dispositivo.nombre }}</p>
                                        </div>
                                        <p
                                            class="font-small-4 font-weight-bold mb-0"
                                        >
                                            Otorgado por
                                        </p>
                                        <p>{{ dispositivo.otorgado_por }}</p>
                                        <p
                                            class="font-small-4 font-weight-bold mb-0"
                                        >
                                            Entidad
                                        </p>
                                        <p>{{ dispositivo.entidad }}</p>
                                        <p
                                            class="font-small-4 font-weight-bold mb-0"
                                        >
                                            Última medición
                                        </p>
                                        <p>
                                            {{
                                                formatDate(
                                                    dispositivo.ultima_medicion,
                                                    "DD MMM YYYY hh:mm a"
                                                ) || "Sin fecha"
                                            }}
                                        </p>
                                        <p class="font-small-2 text-center">
                                            Tenencia desde
                                            {{
                                                formatDate(
                                                    dispositivo.fecha_desde
                                                )
                                            }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-card>
            </b-col>
        </b-row>
        <!-- ASISTENCIAS SECTION -->
        <div class="card p-2 mb-3">
            <h4>Asistencias</h4>
            <b-row class="d-flex justify-content-end my-2">
                <b-col cols="auto">
                    <b-form-input></b-form-input>
                </b-col>
                <b-col cols="auto">
                    <b-button variant="dark" pill class="mr-1 p-50">
                        <feather-icon
                            icon="SearchIcon"
                            size="20"
                        ></feather-icon>
                    </b-button>
                    <b-button
                        variant="gradient-primary"
                        pill
                        class="p-50"
                        @click="buttonOrderBy"
                        v-b-tooltip.hover.top="
                            orderBy == 'desc'
                                ? 'Ordenar ascendentemente'
                                : 'Ordernar descendentemente'
                        "
                    >
                        <feather-icon
                            icon="ArrowUpIcon"
                            size="20"
                            :class="{ 'text-dark': orderBy == 'asc' }"
                        ></feather-icon>
                        <feather-icon
                            icon="ArrowDownIcon"
                            size="20"
                            :class="{ 'text-dark': orderBy == 'desc' }"
                        ></feather-icon>
                    </b-button>
                </b-col>
            </b-row>
            <div class="row justify-content-center">
                <div v-if="!asistencias.length">
                    <div class="row">
                        <p class="text-large-2">
                            ¡Ups! Este paciente no tiene asistencias aún.
                        </p>
                    </div>
                </div>
                <card-asistencia
                    v-else
                    v-for="(asistencia, index) in asistencias"
                    :key="index"
                    :asistencia="asistencia"
                />
            </div>
        </div>
        <!-- modal eliminar paciente -->
        <modal-general
            title="Eliminar paciente"
            ref="refmodalEliminarPaciente"
            text="¿Deseas eliminar este paciente?"
            aceptar="Eliminar"
        >
        </modal-general>
        <modal-general
            title="Retirar dispositivo"
            ref="refmodalRetirarDispositivo"
            text="¿Deseas retirar este dispositivo?"
            aceptar="Retirar"
            @aceptar="retirarDispositivo"
        >
        </modal-general>
        <!-- sidebar agregar dispositivo -->
        <sidebar-agregar-dispositivo
            @update="fetchVerPaciente"
            ref="refsidebarAgregarDispositivo"
        ></sidebar-agregar-dispositivo>

        <sidebar-editar-paciente
            ref="refsidebarEditarPaciente"
            @update="fetchVerPaciente"
        >
        </sidebar-editar-paciente>
    </div>
</template>
<script>
import dayjs from "dayjs";
import { useRouter } from "@/@core/utils/utils.js";
import Pacientes from "@/services/pacientes";
import { ref, reactive, onMounted } from "@vue/composition-api";
export default {
    emits: ["update", "aceptar"],
    components: {
        SidebarAgregarDispositivo: () =>
            import("./sidebars/SidebarAgregarDispositivo.vue"),
        SidebarEditarPaciente: () =>
            import("./sidebars/SidebarEditarPaciente.vue"),
        CardAsistencia: () =>
            import("@/components/asistencias/card_asistencia.vue"),
    },
    setup(props, context) {
        const route = useRouter();

        const idPaciente = ref(route.route.value.params.id);
        const idUserDispositivoSelected = ref(null);

        const orderBy = ref("asc");
        const canEdit = ref(false);

        const paciente = ref({});

        const asistencias = ref([]);

        const entidades = ref([]);

        const dispositivos = ref([]);

        function buttonOrderBy() {
            orderBy.value == "asc"
                ? (orderBy.value = "desc")
                : (orderBy.value = "asc");
        }

        function abrirModalEliminarPaciente() {
            context.refs.refmodalEliminarPaciente.toggle();
        }

        function abrirSidebarAgregarDispositivo() {
            const params = {
                id_entidad: paciente.value.id_entidad,
                id_user: paciente.value.id,
            };
            context.refs.refsidebarAgregarDispositivo.toggle(params);
        }

        function abrirSidebarEditarPaciente() {
            context.refs.refsidebarEditarPaciente.toggle(paciente.value);
        }

        function abrirModalRetirarDispositivo(idUserDispositivo) {
            idUserDispositivoSelected.value = idUserDispositivo;
            context.refs.refmodalRetirarDispositivo.toggle();
        }

        async function fetchVerPaciente() {
            try {
                const { data } = await Pacientes.verPaciente(idPaciente.value);
                paciente.value = data.data;
                entidades.value = data.entidades;
                dispositivos.value = data.dispositivos.map((el) => {
                    el.showDetalle = false;
                    return el;
                });
            } catch (error) {
                context.root.catch(error);
            }
        }

        async function fetchAsistenciasPaciente() {
            try {
                const { data } = await Pacientes.listarAsistenciasPacientes(
                    idPaciente.value
                );

                asistencias.value = data.data;
            } catch (error) {
                context.root.catch(error);
            }
        }

        async function retirarDispositivo(idUserDispositivo) {
            try {
                const { data } = await Pacientes.retirarDispositivo(
                    idUserDispositivoSelected.value
                );

                context.root.notificacion(data.message, null, "success");

                fetchVerPaciente();
                context.refs.refmodalRetirarDispositivo.toggle();
            } catch (error) {
                context.root.catch(error);
            }
        }

        onMounted(() => fetchVerPaciente());

        return {
            abrirModalEliminarPaciente,
            asistencias,
            buttonOrderBy,
            orderBy,
            dispositivos,
            canEdit,
            abrirSidebarAgregarDispositivo,
            abrirSidebarEditarPaciente,
            paciente,
            entidades,
            fetchVerPaciente,
            retirarDispositivo,
            abrirModalRetirarDispositivo,
        };
    },
};
</script>
<style lang="css" scope>
.dispositivo {
    object-fit: fill;
}
</style>
